<template>
  <div>
    <div class="tabs">
      <div class="container">
        <p @click="tabActive = 1" :class="{active: tabActive === 1}">双控</p>
        <p @click="tabActive = 2" :class="{active: tabActive === 2}">安全标准化</p>
        <p @click="tabActive = 3" :class="{active: tabActive === 3}">安全托管</p>
        <p @click="tabActive = 4" :class="{active: tabActive === 4}">隐患排查</p>
        <p @click="tabActive = 5" :class="{active: tabActive === 5}">应急预案</p>
      </div>
    </div>
    <!--  双控  -->
    <div class="container" v-if="tabActive === 1">
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">建设背景</p>
      <span class="fs-14 color-info">习近平总书记于2016年1月中共中央政治局常委会议上首次提出“风险分级管控与隐患排查体系”，明确提出必须坚决遏制重特大事故频发势头，对易发重特大事故的行业领域采取风险分级管控、隐患排查治理双重预防性工作机制，推动安全生产关口前移，加强应急救援工作，最大限度减少人员伤亡和财产损失。</span>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">“双重预防机制”定义</p>
      <div class="display-flex ai-center jc-space-between">
        <div style="width: 49%;padding: 32px 40px;display: flex;align-items: center;border: 1px solid #CCCCCC;">
          <img width="80px" height="80px" src="@/assets/img/sk-icon-01.png" alt="">
          <div class="ml-20">
            <p class="fs-20 mb-12">第一道：管风险</p>
            <span class="fs-14 color-info">通过定性定量的方法把风险用数值表现出来，对风险分级，让企业结合风险大小合理调配资源，分层分级管控不同等级的风险</span>
          </div>
        </div>
        <div style="width: 49%;padding: 32px 40px;display: flex;align-items: center;border: 1px solid #CCCCCC;">
          <img width="80px" height="80px" src="@/assets/img/sk-icon-02.png" alt="">
          <div class="ml-20">
            <p class="fs-20 mb-12">第二道：治隐患</p>
            <span class="fs-14 color-info">排查风险管控过程中出现的缺失、漏洞和风险控制失效环节，整治这些失效环节，动态的管控风险，有效遏制重特大事故的发生</span>
          </div>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">法规依据</p>
      <div class="fgyj">
        <img src="@/assets/img/fgyj.png" alt="">
        <div>
          <p>《中华人民共和国安全生产法》</p>
          <p>《关于实施遏制特大事故工作指南构建双重预防机制的意见》（安委办[2016]11号）</p>
          <p>《省安监局关于印发进一步加强企业安全风险分级管控和隐患排查治理双重预防机制建设工作指导意见的通知》（苏安监[2017]60号）</p>
          <p>《苏州市安监局关于印发进一步加强企业安全风险分级管控和隐患排查治理双重预防机制建设工作实施方案的通知》（苏安监综〔2017〕16号）</p>
          <p>《关于加快推进全市企业安全风险分级管控和隐患排查治理双重预防机制建设的通知》（苏安办〔2017〕194号）</p>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">服务内容</p>
      <div class="fwnr">
        <div class="text">
          <p>安胜达“双重预防机制”咨询服务，根据《国务院安委会关于实施遏制重特大事故工作指南构建双重预防机制的意见》等政策要求，结合专业团队多年的安全管理理论方法和企业安全管理的经验积累，以专业标准化的安全咨询方案为基础，针对企业安全风险特点，与生产现场对接，精准解决风险分级管控和隐患排查治理制度落地。</p>
          <p>我们的服务不仅仅是简单的体系建立、报告编制、现场审核，而是能够帮助企业梳理安全管理流程，规避现场安全风险，全面治理隐患，从而有效提高客户安全管理水平，从而达到预防事故的目的。</p>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">服务优势</p>
      <img class="flow-img" width="100%" src="@/assets/img/flow01.png" alt="">
      <div class="display-flex jc-space-between" style="margin-bottom: 60px;">
        <div style="width: 32%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/sk-img01.png"/>
          <div class="padding-20">
            <p style="text-align: center;" class="mb-10">专业的工程师团队</p>
            <span class="fs-14 color-info">为企业服务的不是一个人，而是整个项目组团队，项目负责人均由超过20年EHS从业经验的高级工程师担任，项目组成员由多位现场安全管理经验丰富的安全工程师组成</span>
          </div>
        </div>
        <div style="width: 32%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/sk-img02.png"/>
          <div class="padding-20">
            <p style="text-align: center" class="mb-10">多年的服务经验积累</p>
            <span class="fs-14 color-info">我们为超过3000家的大中小型企业提供过超过20000次的安全咨询服务，有着多年的成功经验积累和专业化的知识沉淀，为客户提供最专业的服务 </span>
          </div>
        </div>
        <div style="width: 32%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/sk-img03.png"/>
          <div class="padding-20">
            <p style="text-align: center" class="mb-10">标准化的服务保障体系</p>
            <span class="fs-14 color-info">我们各个类型的服务项目都有标准化的服务流程与保障体系，从而确保为客户提供最优质的服务</span>
          </div>
        </div>
      </div>
    </div>
    <!--  安全标准化  -->
    <div class="container" v-if="tabActive === 2">
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">安全生产标准化介绍</p>
      <span class="fs-14 color-info">安全生产标准化体现了“安全第一、预防为主、综合治理”的方针和“以人为本”的科学发展观，强调企业安全生产工作的规范化、科学化、系统化和法制化，通过建立安全生产责任制，制定安全管理制度和操作规程，排查治理隐患和监控重大危险源，建立预防机制，规范生产行为，使各生产环节符合有关安全生产法律法规和标准规范的要求，人、机、物、环处于良好的生产状态，并持续改进，不断加强企业安全生产规范化建设。</span>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">安全生产标准化分类</p>
      <span class="fs-14 color-info">安全生产标准化跟进企业行业及规模，分为一级、二级、三级、小微标准化不同级别，根据不同级别为企业提供项目策划、体系建立、专家诊断、评审辅导等规范化服务</span>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">服务内容职责</p>
      <div class="display-flex" style="flex-wrap: wrap">
        <div style="width: 483px;display: flex;align-items: center;margin: 10px 10px 10px 0;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/aqbzh-icon01.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业建立安全标准化体系文件</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/aqbzh-icon02.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">协助企业建立健全安全生产责任制</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/aqbzh-icon03.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">完善企业安全管理制度和操作规程</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px 10px 10px 0;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/aqbzh-icon04.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业排查治理隐患、监控重大隐患</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/aqbzh-icon05.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业标准化自评及申报</p>
          </div>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">创建收益</p>
      <div class="display-flex jc-space-between">
        <div style="width: 19%;">
          <img width="100%" src="@/assets/img/aqbzh-img01.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -60px;font-size: 14px;">提升企业品牌形象，树立企业的引领示范作用</p>
        </div>
        <div style="width: 19%;">
          <img width="100%" src="@/assets/img/aqbzh-img02.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -60px;font-size: 14px;">改善安全生产条件，提升安全管理水平</p>
        </div>
        <div style="width: 19%;">
          <img width="100%" src="@/assets/img/aqbzh-img03.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -60px;font-size: 14px;">为企业的安全文化建设提供强有力的支撑</p>
        </div>
        <div style="width: 19%;">
          <img width="100%" src="@/assets/img/aqbzh-img04.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -60px;font-size: 14px;">全面消除重大安全隐患</p>
        </div>
        <div style="width: 19%;">
          <img width="100%" src="@/assets/img/aqbzh-img05.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -60px;font-size: 14px;">创造政企良好的安全沟通渠道</p>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 40px;margin-bottom: 30px;">服务优势</p>
      <img class="flow-img" width="100%" src="@/assets/img/flow02.png" alt="">
    </div>
    <!--  安全托管  -->
    <div class="container" v-if="tabActive === 3">
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">什么是安全托管？ </p>
      <div style="border: 1px solid #D8D8D8;display: flex;align-items: center;">
        <img src="@/assets/img/aqtg-img01.png" height="183px"/>
        <div class="padding-20">
          <span class="fs-14 color-info">部分中小企业规模小，从业人员安全素质偏低，安全投入少，安全管理人员配备不足，安全管理知识和能力匮乏，安全管理“无人管”“不会管”等问题突出，给企业发展，乃至地区社会稳定带来较大风险，为了解决中小企业的安全管理问题。</span><br>
          <span class="fs-14 color-info display-block mt-16">2006年，广州市政府率先创新了把安全管理的“软件”委托给安全技术服务机构，接受其安全技术人员指导，按照机构提供的方案，完善企业安全管理，从而降低事故发生的概率 。这种现代管理方式，就叫 <span style="color: #05B4FF">安全生产托管</span></span>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">服务收益</p>
      <div class="display-flex jc-space-between">
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/aqtg-img02.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">改善了企业安全管理“无人管”“不会管”的状况</p>
        </div>
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/aqtg-img03.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">改善安全生产条件，提升安全管理水平</p>
        </div>
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/aqtg-img04.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">为企业的安全文化建设提供强有力的支撑</p>
        </div>
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/aqtg-img05.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">全面消除重大安全隐患</p>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 40px;margin-bottom: 30px;">服务优势</p>
      <img class="flow-img" width="100%" src="@/assets/img/flow03.png" alt="">
    </div>
    <!--  隐患排查  -->
    <div class="container" v-if="tabActive === 4">
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">什么是隐患排查？ </p>
      <div style="border: 1px solid #D8D8D8;display: flex;align-items: center;">
        <img src="@/assets/img/yhpc-img01.png" height="183px"/>
        <div class="padding-20">
          <span class="fs-14 color-info">第三方安全技术服务机构通过技术化、专业化，采用先进管理方法，利用科学和专业的隐患排查流程和隐患排查方法，配合使用相应的检测设备，对企业生产现场隐患进行排查、辨识、判定、测评，确定隐患风险评估等级及状态，制定相应的整改措施，企业对检查到安全隐患进行整改。</span><br>
          <span class="fs-14 color-info display-block mt-16">将企业安全隐患和安全生产事故消灭在萌芽状态，将企业的安全管理水平和现场安全生产风险控制在一个可接受的范围内。</span>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">服务内容</p>
      <div class="display-flex">
        <div style="width: 24%;display: flex;align-items: center;margin: 10px 10px 10px 0;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yhpc-icon01.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业建立安全标准化体系文件</p>
          </div>
        </div>
        <div style="width: 24%;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yhpc-icon02.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">协助企业建立健全安全生产责任制</p>
          </div>
        </div>
        <div style="width: 24%;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yhpc-icon03.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">完善企业安全管理制度和操作规程</p>
          </div>
        </div>
        <div style="width: 24%;display: flex;align-items: center;margin: 10px 10px 10px 0;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yhpc-icon04.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业排查治理隐患、监控重大隐患</p>
          </div>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">预期成果</p>
      <div class="display-flex jc-space-between">
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/yhpc-img02.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">三违”明显减少</p>
        </div>
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/yhpc-img03.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">风险全面梳理，隐患得到治理</p>
        </div>
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/yhpc-img04.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">各级岗位责任制落实，员工履职能力不断提升</p>
        </div>
        <div style="width: 24%;border: 1px solid #CCCCCC;">
          <img width="100%" src="@/assets/img/yhpc-img05.png" alt="">
          <p style="text-align: center;padding: 20px;font-size: 14px;color:#666666;">事故发生频次显著下降，重大事故几率持续降低</p>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 40px;margin-bottom: 30px;">服务优势</p>
      <img class="flow-img" width="100%" src="@/assets/img/flow04.png" alt="">
    </div>
    <div class="container" v-if="tabActive === 5">
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">什么是应急预案？ </p>
      <span class="fs-14 color-info">生产安全事故应急预案编制服务，是指辅助企业进行事故风险辨识、评估，应急资源调查，安全生产事故应急预案（综合应急预案、专项应急预案、现场处置方案）编制，并根据《生产安全事故应急预案管理办法》(应急2号令)的要求组织专家对应急预案进行评审、形成书面评审纪要，辅助生产经营单位准备备案材料、申报应急预案备案、取得备案回执的服务过程。</span>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">服务内容</p>
      <div class="display-flex" style="flex-wrap: wrap">
        <div style="width: 483px;display: flex;align-items: center;margin: 10px 10px 10px 0;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yjya-icon01.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业进行事故风险辨识、评估</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yjya-icon02.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业进行应急资源调查</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yjya-icon03.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业编制生产安全事故应急预案</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px 10px 10px 0;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yjya-icon04.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业编制生产安全事故应急预案</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yjya-icon05.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">辅助企业申报备案</p>
          </div>
        </div>
        <div style="width: 483px;display: flex;align-items: center;margin: 10px;">
          <div style="background-color:#ECEFF9;padding: 20px 35px;">
            <img width="40px" height="40px" src="@/assets/img/yjya-icon06.png" alt="">
          </div>
          <div style="width: 100%;background-color:#F6F7FB;height: 100%;display: flex;align-items: center;" class="fs-14">
            <p class="pl-20 pr-20">应急培训及演练辅导</p>
          </div>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 60px;margin-bottom: 30px;">创建收益</p>
      <div class="display-flex jc-space-between">
        <div style="width: 24%;">
          <img width="100%" src="@/assets/img/yjya-img01.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -84px;font-size: 14px;">企业应急响应机制得到完善</p>
        </div>
        <div style="width: 24%;">
          <img width="100%" src="@/assets/img/yjya-img02.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -84px;font-size: 14px;">从容应对各类突发事件</p>
        </div>
        <div style="width: 24%;">
          <img width="100%" src="@/assets/img/yjya-img03.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -84px;font-size: 14px;">打通政企应急沟通渠道</p>
        </div>
        <div style="width: 24%;">
          <img width="100%" src="@/assets/img/yjya-img04.png" alt="">
          <p style="color: #FFFFFF;text-align: center;padding: 0 20px;position:relative;top: -84px;font-size: 14px;">有效协调管理应急资源</p>
        </div>
      </div>
      <p class="fs-24" style="margin-top: 40px;margin-bottom: 30px;">服务优势</p>
      <img class="flow-img" width="100%" src="@/assets/img/flow05.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'AQZX',
  components: {},
  props: {},
  data () {
    return {
      tabActive: 1
    }
  },
  filter: {},
  computed: {},
  watch: {},
  methods: {},
  beforeCreate () {
  },
  created () {

  },
  beforeMount () {
  },
  mounted () {
    if (this.$route.query.tabActive) {
      this.tabActive = Number(this.$route.query.tabActive)
    }
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  width: 100%;
  height: 70px;
  background-color: #F2F2F2;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      cursor: pointer;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .active {
      background-color: #49A9ED;
      color: white;
    }
  }
}
.fgyj {
  display: flex;
  border: 1px solid #E6E6E6;
  img {
    width: 544px;
    height: 232px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    p {
      font-size: 14px;
      position: relative;
      &:before {
        content: ' ';
        position: absolute;
        left: -12px;
        top: 6px;
        width: 8px;
        height: 8px;
        background-color: #49A9ED;
        border-radius: 50%;
      }
    }
  }
}
.fwnr {
  margin-top: 30px;
  background: url("~@/assets/img/fwnr-bg.png");
  background-size: cover;
  height: 230px;
  padding: 30px 40px;
  .text {
    font-size: 15px;
    color: #FFFFFF;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    flex-direction: column;
    p {
      margin-left: 30px;
      line-height: 1.8;
    }
  }
}
.flow-img {
  margin-bottom: 40px;
  box-shadow: 0 0 20px 0 rgba(217, 217, 217, 0.5);
}
</style>
